$white-02: #fff;
$white-04: #f2f2f2;
$white-05: #dee5f0;

$grey-01: #dfdfdf;
$grey-02: #e9ecef;
$grey-05: #ccc;
$grey-06: #ddd;
$grey-07: #9d9d9d;
$grey-09: #666666;
$grey-10: #444444;

$green-01: #198754;
$red-01: #dc3545;
$yellow-01: #ffc107;
$yellow-02: #ffcd00;


$black: #000;
$black-02: #00000020;
$black-03: #222222;
$black-04: #0000004d;

$blue-01: #06235b;
$blue-02: #052357;
$blue-04: #0a3588;
$blue-07: #175197;


// Font Weight
$font-weight-bold: bold;
$font-weight-semibold: 600;
$font-weight-400: 400;
$font-weight-500: 500;
$success-text: #458569;

// Proxima Nova
$font-proxima-nova: "Proxima Nova";
$font-proxima-nova-url: "https://dle-cdn.mheducation.com/mhe/fonts/proxima-nova/";
$font-proxima-nova-extra-condensed: "proxima_novacondensed";
$font-proxima-nova-extra-condensed-url: "https://dle-cdn.mheducation.com/mhe/fonts/proxima-nova-extra-condensed/";
