.btn {
  &:first-child:active {
    border-color: $blue-04;
    background-color: $blue-01 !important;
  }

  &:active {
    background-color: $blue-04 !important;
    color: $white-02;
    box-shadow: none;
    border: none;
  }
}
.btn-primary {
  background-color: $blue-01;
  color: $white-02;
  box-shadow: none;
  border: none;

  &:hover,
  &:active {
    background-color: $blue-04;
    color: $white-02;
    box-shadow: none;
  }

  &:focus-visible {
    background-color: $blue-01;
    color: $white-02;
    outline-style: solid;
    outline-color: $blue-01;
    outline-width: 0.125rem;
    outline-offset: 0.063rem;
    border-color: $white-02;
    box-shadow: none;
  }

  &:disabled {
    background-color: $blue-04;
    color: $white-02;
    opacity: 0.5;
    pointer-events: none;
  }
}

.btn-outline-primary {
  color: $blue-02;
  border-color: $blue-02;
  box-shadow: none;

  &:hover,
  &:active {
    background-color: $blue-04;
    color: $white-02;
    box-shadow: none;
    border: 0.063rem solid $blue-04;
  }

  &:focus-visible {
    outline-style: solid;
    outline-color: $blue-02;
    outline-width: 0.125rem;
    outline-offset: 0.063rem;
    background-color: $blue-01;
    color: $white-02;
    border-color: $white-02;
    box-shadow: none;
    --bs-btn-focus-box-shadow: $white-02;
  }

  &:disabled {
    color: $blue-02;
    border-color: $blue-02;
    opacity: 0.5;
    pointer-events: none;
  }
}

.btn.show {
  background-color: $blue-01;
  border-color: $blue-01;
}
