@mixin up-accordion-icon-hover-state {
  color: $blue-04;
  background-color: $white-02 !important;
  outline-style: solid;
}

@mixin up-accordion-icon-outline-state {
  @include up-accordion-icon-hover-state;
  border: 0.063rem solid;
  outline-style: solid !important;
  outline-color: $blue-01 !important;
  outline-width: 0.125rem !important;
  outline-offset: 0.125rem !important;
}

.up-accordion-icon {
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem !important;
  
  &:hover {
    @include up-accordion-icon-hover-state;
  }

  &:focus-visible,
  &:focus-within {
    @include up-accordion-icon-outline-state;
  }

  &:not(.collapsed) {
    background-color: transparent !important;
    box-shadow: none;
  }
}

.btn.up-accordion-icon {
  &:active {
    @include up-accordion-icon-hover-state;
  }
}

.accordion-button {
  &:after {
    content: none;
  }

  &:focus {
    box-shadow: none;
  }
}

.accordion-header {
  padding: 0.813rem 0rem;
  flex-direction: row;

  &__trigger {
    justify-content: flex-start;
    align-content: center;
    padding-left: 0.375rem;
  }

  &__edit-checkbox {
    margin-right: 0.813rem;
  }
  
  &__title {
    justify-content: flex-start;
    padding-left: 0.375rem;
  }
}

.accordion-content {
  margin-left: 1.563rem;
}
